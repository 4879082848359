import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import SecondScreenComponent from './GetStartedPage';
import FinalScreenExam from './MarksObtainedPage';
import './styles.css';

export default function FirstScreenExam(props) {
  const {
    ExamData,
    setReload,
    choose, setChoose,
    AnswerArray, setAnswerArray,
    ResultData, setResultData
  } = props;
  const LoginUser = useSelector((state) => state.LoginState);
  const history = useHistory();
  const location = useLocation();
  const [QuestionsData, setQuestionsData] = React.useState(
    JSON.stringify(location).includes("start-demo-exam") ?
      JSON.parse(ExamData.meta.exam_question_text) :
      JSON.parse(ExamData.meta.exam_question_block_text))
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(
    JSON.stringify(location).includes("start-demo-exam") ?
      JSON.parse(ExamData.meta.exam_question_text)["item-0"] :
      JSON.parse(ExamData.meta.exam_question_block_text)["item-0"]
  )
  const [PlaySingle, setPlaySingle] = React.useState(ExamData.meta.media_non_repeat);
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");
  const [TimingStarted, setTimingStarted] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({})
  const [obtainedMarks, setObtainedMarks] = React.useState(0)
  const [mediaPlayedJSON, setMediaPlayed] = React.useState({})
  const [batchExpired, setBatchExpired] = React.useState({})

  useEffect(() => {
    var mediaPlayed = {};
    const ArrayValue = JSON.stringify(location).includes(slugs.start_demo_exam) ?
      Object.values(JSON.parse(ExamData.meta.exam_question_text)) :
      Object.values(JSON.parse(ExamData.meta.exam_question_block_text))
    for (let i = 0; i < ArrayValue.length; i++) {
      const QuestionJSON = ArrayValue[i]
      mediaPlayed = {
        ...mediaPlayed,
        [`question-${i + 1}`]: "0",
        [`answer-${i + 1}-a`]: "0",
        [`answer-${i + 1}-b`]: "0",
        [`answer-${i + 1}-c`]: "0",
        [`answer-${i + 1}-d`]: "0",
      }
      setMediaPlayed(mediaPlayed)
      if (AnswerArray.length < ArrayValue.length)
        AnswerArray.push({
          question_number: QuestionJSON.question_number,
          correct_answer: QuestionJSON.correct_answer,
          answerChoosen: "",
          solved: 0
        })
    }
    // if (LoginUser.userBatch !== "") {
    //   if (!location.pathname.includes(slugs.start_demo_exam)) {
    //     AdsGETApiAuth({ _fields: "id,meta.batch_status" }, `${slugs.db_slug_batch}/${LoginUser.userBatch}`)
    //       .then((res) => {
    //         console.log(res.data.meta.batch_status)
    //         if (res.data.meta.batch_status !== "Running")
    //           setChoose(3)
    //       })
    //   }
    // }
    // else
    //   if (!location.pathname.includes(slugs.start_demo_exam))
    //     setChoose(3)
    // handle.enter()

  }, [])
  const SubmitExam = () => {
    setReload(true)
    const token = store.getState().LoginState.token
    const id = store.getState().LoginState.userID;
    var correct = 0;
    const date = new Date();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen)
        correct++
    }
    const data = {
      title: `Exam-${ExamData.title.rendered} User-${id} Date-${date.getUTCFullYear()}/${month}/${day}`,
      status: "publish",
      meta: {
        "exam_answers_array_results": JSON.stringify(AnswerArray),
        "exam_date_results": `${date.getUTCFullYear()}-${month}-${day}`,
        "exam_name_results": `${ExamData.title.rendered}`,
        "exam_id_results": `${ExamData.id}`,
        "exam_total_results": `${correct}`,
        "student_id_results": `${id}`
      }
    }
    // const slug=location.pathname.slice(slugs.start_live_exam_admin.length + 1)
    // const dbslug = slug.includes("chapter") ? slugs.db_slug_chapter_results : slugs.db_slug_paid_results
    // AdsPOSTApi(data, dbslug)
    //   .then((res) => {
    //     setReload(false)
    //     setResultData(res.data)
    //     setChoose(2)
    //   }).catch((err) => {
    //     console.log(err)
    //     console.log(err.response.data)
    //   })

    // if (location.pathname.includes(slugs.start_exam))
    //   AdsPOSTApi(data, slugs.db_slug_paid_results)
    //     .then((res) => {
    //       setReload(false)
    //       setResultData(res.data)
    //       setChoose(2)
    //     }).catch((err) => {
    //       console.log(err)
    //       console.log(err.response.data)
    //     })
    // else if (location.pathname.includes(slugs.start_daily_exam))
    //   AdsPOSTApi(data, slugs.db_slug_daily_results)
    //     .then((res) => {
    //       setReload(false)
    //       setResultData(res.data)
    //       setChoose(2)
    //     }).catch((err) => {
    //       console.log(err)
    //       console.log(err.response.data)
    //     })
    // else if (location.pathname.includes(slugs.start_chapter_wise_exam))
    //   AdsPOSTApi(data, slugs.db_slug_chapter_results)
    //     .then((res) => {
    //       setReload(false)
    //       setResultData(res.data)
          setChoose(2)
    //     }).catch((err) => {
    //       console.log(err)
    //       console.log(err.response.data)
    //     })
    // else
      setReload(false)
  }
  return (
    <div>
      <div style={{ border: "1px solid #aaa", padding: "0px 0 10px", backgroundColor: "#fff" }}>
        {choose == 0 &&
          <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
            <div style={{ width: "100%" }}>
              <div style={{ textAlign: 'center' }}>
                <h1>{ExamData.title.rendered}</h1>
                <br />
                {LoginUser.userPhoto === undefined ?
                  <img src={`https://api.epsexam.com/wp-content/uploads/2022/05/User_font_awesome.png`}
                    height="30px"
                  />
                  :
                  <img src={`${LoginUser.userPhoto}`} className={"height-width-150 border border-dark"} />
                }
              </div>
              <div>
                <br />
                <b>Name of Student: {LoginUser.userName}</b>
                <br />
                <b>Student Email: {LoginUser.userEmail}</b>
                <br />
              </div>
              <div>
                <b>Exam description</b>
                <br />
                <div dangerouslySetInnerHTML={{ __html: ExamData.meta.exam_details }} />
                {/* {ExamData.content.rendered} */}
              </div>
              <br />
              <div className="row">
                <div className="col-lg-4 col-12" />
                <div className="col-lg-4 col-12 center">
                  <button className="sign-in-button-11 col-lg-8 col-7"
                    onClick={() => {
                      setChoose(1)
                      // setTimingStarted(true)
                    }}
                  >
                    Get Started (सुरु)
                  </button>
                  <button className="sign-in-button-4 col-lg-6 col-6"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Cancel (रद्द)
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
        {choose == 1 &&
          <div>
            <SecondScreenComponent
              QuestionsEditKey={QuestionsEditKey} setQuestionsEditKey={setQuestionsEditKey}
              QuestionsEditData={QuestionsEditData} setQuestionsEditData={setQuestionsEditData}
              QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
              QuestionsEditMode={QuestionsEditMode} setQuestionsEditMode={setQuestionsEditMode}
              TimingStarted={TimingStarted} setTimingStarted={setTimingStarted}
              ExamData={ExamData}
              AnswerArray={AnswerArray}
              setChoose={setChoose}
              SubmitExam={SubmitExam}
              mediaPlayedJSON={mediaPlayedJSON}
              PlaySingle={PlaySingle}
            />
          </div>
        }
        {choose == 2 &&
          <div>
            <FinalScreenExam
              ExamData={ExamData}
              QuestionsData={QuestionsData}
              AnswerArray={AnswerArray}
              userDetails={userDetails}
              ResultData={ResultData}
            />
          </div>
        }
        {choose == 3 &&
          <section>
            <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
              <div style={{ width: "100%" }}>
                <div style={{ textAlign: 'center' }}>
                  <h1>{ExamData.title.rendered}</h1>
                  <br />
                  {LoginUser.userPhoto === undefined ?
                    <img src={`https://api.epsexam.com/wp-content/uploads/2022/05/User_font_awesome.png`}
                      height="30px"
                    />
                    :
                    <img src={`${LoginUser.userPhoto}`} className={"height-width-150 border border-dark"} />
                  }
                </div>
                <div>
                  <br />
                  <b>Name of Student: {LoginUser.userName}</b>
                  <br />
                  <b>Student Email: {LoginUser.userEmail}</b>
                  <br />
                </div>
                <div>
                  <b>Exam description</b>
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: ExamData.meta.exam_details }} />
                  {/* {ExamData.content.rendered} */}
                </div>
                <br />
                <b>Batch Details</b>
                <div>
                  Your Batch has been expired or is invalid.
                  Please contact admin to start your exam
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-lg-4 col-12" />
                  <div className="col-lg-4 col-12 center">
                    <a href="/profile">
                      <button className="sign-in-button-11 col-lg-8 col-7"
                        onClick={() => {

                        }}
                      >
                        Go to Profile
                      </button>
                    </a>
                    <button className="sign-in-button-4 col-lg-6 col-6"
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      Cancel (रद्द)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    </div>
  )
}
