import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { AdsGETApiAuth } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact: true,
    width: "100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true,
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true,
    center: true,
  },
  {
    id: 33,
    name: "Availability",
    selector: (row) => row.meta.available_unavailable,
    sortable: true,
    reorder: true,
    width: "150px",
    compact: true,
    center: true,
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.start_live_exam_admin}/${row.slug}`}>View</Link>,

  }
];

export default function AllExamListLive() {
  const [Projects1, setProjects1] = React.useState([])
  const [AllExamList, setAllExamList] = React.useState([])
  const [ChapterExamList, setChapterExamList] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [jsonArrange, setJSONArrange] = React.useState("")
  const GetAllProjects = (params, slug, setData) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        var finaljson = [];
        setData(res.data)
        if(slug=slugs.db_slug_chapter_wise_exam){
          setProjects1(res.data)
        }
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 200,
      _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.exam_price,meta.exam_reset_price"
    },
      slugs.db_slug_paid_exam,
      setAllExamList
    )
    GetAllProjects({
      per_page: 200,
      _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.exam_price,meta.exam_reset_price"
    },
      slugs.db_slug_chapter_wise_exam,
      setChapterExamList
    )
  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Exams
        </div>
      </div>
      <a href={slugs.new_exam}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Exam</div>
      </a>
      <div className="col-md-6">
        <div style={{ display: "inline", cursor: "pointer" }}>
         
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setProjects1(ChapterExamList)
            }}
          >
            ChapterWise ({ChapterExamList.length}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setProjects1(AllExamList)
            }}
          >
            All Exam ({AllExamList.length}) |
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "100%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={Projects1}
          defaultSortFieldId={1}
          defaultSortAsc={true}
          striped
          // sortIcon={<SortIcon />}
          // pagination
          selectableRows
          paginationPerPage={50}
          paginationRowsPerPageOptions={[10,20,50,100,200,500]}
        />
      </div>
      {/* <textarea rows={50} cols={200} style={{ width: "900px" }}
        value={JSON.stringify(jsonArrange)} placeholder="Exam Details"
        onChange={(event) => { setJSONArrange(event.target.value) }}
      /> */}
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}