import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FirstScreenExam from '..';
import { AdsGETApiAuth } from '../../../../Constant/api';
import { slugs } from '../../../../Constant/slugs';
import store from '../../../../redux/store';
import NotFound from '../../../NotFound';

export default function StartExamAdmin(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({
    meta:{exam_date_results:""}
  });
  const history = useHistory()
  const [FetchDone, setFetchDone] = React.useState(false)
  const [FetchData, setFetchData] = React.useState({})

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const dbslug = slug.includes("chapter") ? slugs.db_slug_chapter_wise_exam : slugs.db_slug_paid_exam
    AdsGETApiAuth({
      slug: slug,
      _fields: "id,title,slug,meta"
    }, dbslug)
      .then(res => {
        if (res.data.length == 0)
          setNotFound(true)
        else {
          setFetchData(res.data[0])
          setAllExamList(res.data[0])
          setExamFetch(true)
        }
        setTimeout(() => {
          setFetchDone(true)
        }, 100)
      }).catch((e) => {
        setNotFound(true)
        setTimeout(() => {
          setFetchDone(true)
        }, 100)
      })
      .finally(() => {
        setReload(false)
      })

    //   NodePOSTApi({ userID: id, examSlug: slug }, "start-exam-details")
    //     .then(res => {
    //       console.log(res.data)
    //       if (res.data.redirect) {
    //         history.push(`${slugs.exam_payment}/${slug}`)
    //       }
    //       else {
    //         if (res.data.result_data.length !== 0) {
    //           setChoose(2)
    //           console.log(res.data.result_data[0])
    //           setAnswerArray(JSON.parse(res.data.result_data[0].meta.exam_answers_array_results))
    //           setResultData(res.data.result_data[0])
    //         }
    //         setAllExamList(res.data.exam_data[0])
    //         setExamFetch(true)
    //       }
    //     })
    //     .finally(() => {
    //       setReload(false)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       console.log(err.response.data)
    //     })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);

  useEffect(() => {
    fetchExam(location.pathname.slice(slugs.start_live_exam_admin.length + 1))
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
      <div>
        <div className=" form-view m-3">
          {ExamFetch &&
            <>
              {NotFoundData ?
                <NotFound /> :
                <>
                  <FirstScreenExam
                    ExamData={ExamData}
                    setReload={setReload}
                    choose={choose}
                    setChoose={setChoose}
                    AnswerArray={AnswerArray}
                    setAnswerArray={setAnswerArray}
                    ResultData={ResultData} setResultData={setResultData}
                  />
                </>
              }
            </>
          }
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}