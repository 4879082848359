export const slugs = {
  "new_book": "/new-book",
  "books_details": "/admin/books/",
  "all_books": "/all-books",
  "db_slug_book": "books",

  "new_chapter_wise_exam": "/new-chapter",
  "chapter_details": "/chapters/",
  "all_chapters": "/all-chapters",
  "db_slug_chapter_wise_exam": "chapter_wise_exam",

  "new_daily_exam": "/new-daily-exam",
  "daily_exam_details": "/daily-exams/",
  "all_daily_exam": "/all-daily-exam",
  "db_slug_daily_exam": "daily_exam",

  "new_demo_exam": "/new-demo-exam",
  "demo_exam_details": "/demo-exams/",
  "all_demo_exam": "/all-demo-exam",
  "db_slug_demo_exam": "demo_exam",

  "new_exam": "/new-exam",
  "exam_details": "/exams/",
  "all_exam": "/all-exam",
  "db_slug_paid_exam": "paid_exam",
  "result_admin_view":"/result-admin-view",
  "chapter_result_admin_view":"/chapter-result-admin-view",

  "result_details": "/results/",
  "all_results": "/all-results",
  "all_chapter_results": "/all-chapter-results",
  "db_slug_paid_results": "paid_results",

  "daily_result_details": "/daily-results/",
  "all_daily_results": "/all-daily-result",
  "db_slug_daily_results": "daily_results",

  "chapter_wise_result_details": "/chapter-wise-results/",
  "all_chapter_wise_results": "/all-chapter-wise-result",
  "db_slug_chapter_wise_results": "chapter_wise_results",

  "new_student": "/new-student",
  "student_details": "/students/",
  "all_students": "/all-students",
  "db_slug_user_data": "user_data",

  "new_batch": "/new-batch",
  "batch_details": "/batches/",
  "all_batch": "/all-batches",
  "db_slug_batch": "student_batch",



  "exam": "/all-exams-list",
  "demo_exam": "/all-demo-exam-list",
  "chapter_wise_exam": "/all-chapters-list",
  "daily_exam": "/all-daily-exam-list",

  "start_exam": "/start-exam",
  "start_demo_exam": "/start-demo-exam",
  "start_chapter_wise_exam": "/start-chapter-exam",
  "start_daily_exam": "/start-daily-exam",

  "all_live_exam_admin": "/all-live-exam-admin-list",
  "start_live_exam_admin": "/start-exam-admin-list",

  "exam_result_list": "/my-exam-results",
  "daily_exam_result_list": "/my-daily-results",

  "all_live_exam_admin": "/all-live-exam-admin-list",
  "start_live_exam_admin": "/start-exam-admin-list",
  
  "chapter_wise_exam_result_list": "/my-chapterwise-results",

  "exam_result": "/exam-result",
  "daily_exam_result": "/daily-exam-result",
  "chapter_exam_result": "/chapter-exam-result",
}